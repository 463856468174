@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.fancy-scroll-bar {
  float: left;
  overflow-y: auto;
}

.fancy-scroll-bar::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #1d1d1d;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.fancy-scroll-bar::-webkit-scrollbar {
  width: 12px;
  background-color: #1d1d1d;
}

.fancy-scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #3c3c3c;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.fancy-scroll-bar-horizontal {
  float: left;
  overflow-y: auto;
}

.fancy-scroll-bar-horizontal::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #1d1d1d;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.fancy-scroll-bar-horizontal::-webkit-scrollbar {
  width: 12px;
  height: 6px;
  background-color: #1d1d1d;
}

.fancy-scroll-bar-horizontal::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #3c3c3c;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.wallet-adapter-button-trigger {
  display: flex;
  padding-left: 8px;
  white-space: nowrap;
  align-items: center;
}

.wallet-adapter-dropdown-list {
  display: none;
}

.wallet-adapter-modal-button-close {
  display: none;
}

.wallet-adapter-modal-list > li > button {
  width: 100%;
  display: flex;
  padding: 10px;
  font-weight: bold;
  align-items: center;
  justify-content: flex-start;
}

.wallet-adapter-modal-list > li > button:hover {
  background-color: #808080;
}

.wallet-adapter-modal {
  padding: 1.5rem;
}

.wallet-adapter-modal-title {
  padding-bottom: 6px;
}

.wallet-adapter-button-start-icon {
  padding-right: 3px;
}

.wallet-adapter-button-start-icon > img {
  max-width: 30px;
  max-height: 30px;
  padding-right: 4px;
}

.wallet-adapter-button-end-icon > img {
  max-width: 30px;
  max-height: 30px;
}

/********
  Spinner
*********/

.counter {
  /* IMPORTANT */
  overflow: hidden;
}

.counter > span {
  position: relative;
  overflow: hidden;
}

.counter__value {
  /* IMPORTANT: inline elements are not affected by css transforms, so we have
  to convert it to inline-block element */
  display: inline-block;
}

.spinner-enter {
  background: transparent;
  transform: translateY(100%);
}

.spinner-enter.spinner-enter-active {
  transform: translateY(0);
  transition: all 300ms;
}

.spinner-exit {
  transform: translateY(0);
  /* IMPORTANT: so the exiting item will be lined up with the entering item
  , creating a continuous flow effect */
  position: absolute;
  left: 0;
}

.spinner-exit.spinner-exit-active {
  transform: translateY(-100%);
  transition: all 300ms;
}
